<template>
  <div class="wrap">
    <div class="row search-results" id="search-results">
      <!--- Content -->
      <div >

        <div class="results">
          <!-- Item -->
          <article class="result" v-for="(result, key) in results" :key="key">
            <div class="one-fourth heightfix"><img :src="result.img" alt="An image illsutrating the vehicle to be used for the transportation services. These imagese are renderings and the vehicles may not be the same make and model as shown" /></div>
            <div class="one-half heightfix">
              <h3>{{ result.vehicle }} </h3>
              <ul>
                <li>
                  <span class="icon icon-themeenergy_user-3"></span>
                  <p>Max <strong>{{ result.maxPeople }} people</strong> <br />per vehicle</p>
                </li>
                <li>
                  <span class="icon icon-themeenergy_travel-bag"></span>
                  <p>Max <strong>{{ result.maxSuitcases }} suitcases</strong> <br />per vehicle</p>
                </li>
              </ul>
            </div>
            <div class="one-fourth heightfix">
              <div class="price-container">
                <div class="price">{{ result.price }} <small>USD</small></div>
                <h5> + Booking Fee: ${{ (0.035*result.price).toFixed(2) }}</h5>

                <button
                    v-bind:id="result.vehicle"
                    class="btn large color" @click="openReservation(result)">select</button>
              </div>
            </div>
          </article>
          <!-- //Item -->
        </div>
      </div>
      <!--- //Content -->
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "SearchResults",
  props: {
    results:{
      type: Array,
      required: true
    },
    form:{
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions(["setReservation"]),
    openReservation(result) {
      const {form} = this;
      window.scrollTo(0,0);

      this.setReservation({reservation: {...form, ...result}});
      this.$router.replace({name: "reservation-form"});
    },
  }
}
</script>

<style scoped>
p {
  font-size: 12px;
}
.wrap {
  max-width: 60%;
}
.price {
  font-size: 20px;
  font-weight: 500;
}
h5 {
  font-size: 14px;
}
.price-container {
  justify-content: center;
  display: flex !important;
  flex-direction: column;
}
.search-results {
  max-width: 100%;
  margin-top: 20px;
  padding: 0 100px;
}
.results {
  /*margin-top: 20px;*/
  /*margin-left: 50px ;*/
}
.row {
  scroll-margin-top: 250px;
}
@media screen and (max-width: 1200px) {
  .search-results {
    max-width: 100%;
    margin-top: 20px;
    padding: 0 40px;
  }
  .row {
    margin: 0;
  }
  .wrap {
    max-width: 100%;
  }
}

</style>