<template>
  <div>
    <!-- //Search -->
    <div id="next" class="wrap">
      <div class="row">
        <!--- Content -->
        <div class="full-width content">
          <h2>Passenger details</h2>
          <p>Please ensure all of the required fields are completed at the time of booking. This information is
            imperative to ensure a smooth journey.</p>
        </div>
        <!--- //Content -->
        <div class="three-fourth">
          <form>
            <div class="f-row">
              <div class="one-half">
                <input placeholder="First and Last Name" v-model="name" type="text" id="name"/>
                <div class="error_message" v-if="errors.name">{{ errors.name }}</div>
              </div>
              <div class="one-half">
                <input placeholder="Mobile Number" v-model="number" type="text" id="number"/>
                <div class="error_message" v-if="errors.number">{{ errors.number }}</div>
              </div>
            </div>
            <div class="f-row">
              <div class="full-width">
                <input placeholder="E-mail Address" v-model="email" type="email" id="email"/>
                <div class="error_message" v-if="errors.email">{{ errors.email }}</div>
              </div>
            </div>
            <div class="f-row">
            </div>
          </form>
          <table class="data responsive">
            <tr>
              <th>Trip Details</th>
              <th>Arrival</th>
            </tr>
            <tr>
              <td>People <i>(including children)</i></td>
              <td>
                <select v-model="people">
                  <option v-for="(option, key) in peopleOptions" :value="option" :key="key">
                    {{ option }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Pieces of luggage <i>(including ski bags)</i></td>
              <td>
                <select v-model="suitcases">
                  <option v-for="(option, key) in suitcaseOptions" :value="option" :key="key">
                    {{ option }}
                  </option>
                </select>
              </td>
            </tr>

            <tr>
              <td>Convertible Child Seats <i>(Converts between rear and forward facing)</i></td>
              <td>
                <select v-model="infants">
                  <option v-for="(option, key) in peopleOptions" :value="option" :key="key">
                    {{ option }}
                  </option>
                </select>
              </td>
            </tr>

            <tr>
              <td>Booster seats <i></i></td>
              <td>
                <select v-model="booster">
                  <option v-for="(option, key) in peopleOptions" :value="option" :key="key">
                    {{ option }}
                  </option>
                </select>
              </td>
            </tr>
          </table>
          <ReservationSummary :reservation="reservation"/>

          <div class="actions">
            <button @click="goBack" class="btn medium back">Go back</button>
            <button @click="onContinue"
                    id="lead"
                    :disabled="!continueEnabled"
                    :class="{btn: true, color: true, right: true, medium: true, disabled: !continueEnabled}">Continue
            </button>
          </div>

        </div>
      </div>
      </div>
    </div>
</template>

<script>
import ReservationSummary from "./ReservationSummary";
import {mapActions} from "vuex";

export default {
  name: "ReservationStepOne",
  components: {ReservationSummary},
  props: {
    reservation: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      name: '',
      number: '+1',
      email: '',
      errors: {
        name: '',
        flightNumber: '',
        flightNumberReturn: '',
        number: '',
        email: '',
      },
      infants: 0,
      booster: 0,
      people: 0,
      suitcases: 0,
      bookingFee: (this.reservation.price * 0.035).toFixed(2),
      totalStamp: (0.035*this.reservation.price + this.reservation.price).toFixed(2)
    }

  },
  computed: {
    // ...mapGetters(["reservation"]),
    fieldsFilled() {
      return this.name && this.number && this.email;
    },
    continueEnabled() {
      return this.fieldsFilled;
    },
    suitcaseOptions() {
      const {maxSuitcases} = this.reservation;

      if (!maxSuitcases) {
        return [];
      }

      return [...Array(maxSuitcases + 1).keys()];
    },
    peopleOptions() {
      const {maxPeople} = this.reservation;

      if (!maxPeople) {
        return [];
      }

      return [...Array(maxPeople + 1).keys()];
    }
  },
  methods: {
    scrollToTop() {
    },
    ...mapActions(["updateReservation"]),
    onContinue(e) {

      e.preventDefault();

      const {
        people,
        infants,
        suitcases,
        booster,
        name,
        number,
        email,
        bookingFee,
        totalStamp
      } = this;

      console.log(bookingFee, totalStamp);
      Object.keys(this.errors).forEach(error => {
        this.$set(this.errors, error, '');
      })
      const data = {
        ...this.reservation,
        name,
        number,
        email,
        infants,
        booster,
        people,
        suitcases,
        bookingFee,
        totalStamp
      };

      this.updateReservation({data});
      this.$emit("continue");
    },
    goBack() {
      this.$router.replace({name: "home"});
    },
    includesField(name) {
      return this.reservation && Object.keys(this.reservation).includes(name)
    },

  },
  created() {
    const {reservation} = this;
    if (reservation) {
      const {
        name,
        number,
        email,
        flightNumber,
      } = reservation;
      this.name = name;
      this.number = number;
      this.email = email;
      this.flightNumber = flightNumber;

      const fields = ['infants', 'people', 'suitcases', 'booster'];
      fields.forEach(field => {
        if(this.includesField(field)){
          this[field] = this.reservation[field];
        }
      });
  }
},
}
</script>

<style scoped>
  table {
    margin-top: 25px;
  }
@media screen and (max-width: 600px) {
  h2 {
    font-size: 24px;
    padding-bottom: 10px;
  }
  p{
    padding-bottom: 0;
  }
  table td {
    padding: 10px;
    margin-top: 15px;
  }
}

</style>