<template>
  <div id="app" class="home">

    <NavBar/>
    <!-- Main -->
    <router-view/>
  </div>
</template>

<script>

import NavBar from "./components/NavBar";
export default {
  name: 'App',
  components: {NavBar}
}
</script>

<style>
.error-message {
  color: #5e0000;
  margin-bottom: 5px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 80px;
}

.loader {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #191F26; /* Blue */
  border-radius: 50%;
  margin: auto;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>