<template>
  <div id="terms" class="modal-wrapper" v-if="show">
    <div class="modal">
      <header class="color">
        <div class="wrap pl-10">
          <div class="container">
            <h1>Terms and conditions</h1>
          </div>
        </div>
      </header>
      <div class="terms">
        <p>Transportation booked on www.limotoco.com must be within the Denver Metro area, if the trip either originates or the destination of it is outside of Denver Metro, the booking may require additional charges and/or be cancelled because our partners may not be able to fulfill such transportation request.</p>
        <p>The user acknowledges that the transportation booked on this website, www.limotoco.com, will be provided by a third party transportation services provider. Limo to Vail LLP is a booking company that connects the user with transportation services providers. Once the reservation is confirmed, within 24 hours the user will receive information regarding the third party transportation services provider.</p>
        <p>Payments made through Limo to Denver are executed by Square Inc. Limo to Denver does not keep any credit card information, nor do we collect any user information as data to then be sold to other companies. The only data stored by Limo to Denver is in relations to your reservation.</p>
        <p>Any cancellation of a Limo to Denver reservation should it to happen at least 48 hours prior to the pick up time will be refunded to the user in its entirety. Reservations cancelled within 48 hours of the booking time can not be refunded as they will already be paid to the third party transportation services provider. In a case of a flight delay or a severe weather event that limits the ability of the customer to arrive on time for the reservation, please contact our customer service department to determine the how to proceed</p>
      </div>
      <div class="flex agree">
        <button @click="onCancel" class="btn medium back">Cancel</button>
        <button @click="onOk" class="btn color right medium">Continue</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsModal",
  data() {
    return {
      show: false
    }
  },
  methods: {
    onCancel() {
      this.show = false;
    },
    onOk() {
      this.show = false;
    },
    onShow() {
      this.show = true;
    }
  }
}
</script>

<style scoped>
.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  text-align: center;
}
header, h1 {
  text-align: center;
  padding: 5px;
}

p {
  padding: 20px;
  text-align: justify;
}

.flex {
  display: flex;
  justify-content: space-evenly;
}

.terms {
  height: 70%;
  overflow-y: scroll;
  padding: 0 5px;
}
.modal {
  background: white;
  width: 50%;
  height: 500px;
  margin-left: auto;
}
.agree {
  padding: 25px;
}
@media screen and (max-width: 600px){
  .btn, .medium {
    min-width: 120px;
  }
}


</style>