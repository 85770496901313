<template>
 <div class="wrap">
   <template v-if="reservation">
     <header class="site-title color">
       <div class="wrap pl-10">
         <div class="container">
           <font-awesome-icon icon="check-circle" size="2x"/>
           <h1>Valid QR code</h1>
         </div>
       </div>
     </header>
     <ReservationReceipt :reservation="reservation" />
   </template>
   <header v-else class="site-title color">
     <div class="wrap pl-10">
       <div class="container">
         <font-awesome-icon icon="exclamation-circle" size="2x"/>
         <h1>Invalid QR code</h1>
       </div>
     </div>
   </header>
 </div>
</template>

<script>
import ReservationReceipt from "./ReservationReceipt";
import server from "../axios";
export default {
  name: "Reservation",
  components: {ReservationReceipt},
  data() {
    return {
      reservation: null
    }
  },
  methods: {
    init() {
      const {id} = this.$route.params;
      server.get(`/reservations/${id}`).then(res=>{
        this.reservation = res.data;
      });
    }
  },
  created() {
    this.init();
  },
  watch: {
    $route() {
      this.init();
    }
  }
}
</script>

<style scoped>
.site-title {
  margin-top: 50px;
  padding: 10px
}

</style>