<template>
  <div class="modal-wrapper">
    <div class="modal">
      <Inquiry />
    </div>
  </div>
</template>

<script>
import Inquiry from "./Inquiry";
export default {
  name: "TermsModal",
  components: {Inquiry},
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  text-align: center;
}

.modal {
  background: white;
  margin-left: auto;
}
</style>