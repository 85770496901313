<template>
  <div class="three-fourth p-20" v-if="reservation">
    <form class="box readonly">
      <h3> Trip Details </h3>
      <div class="f-row">
        <div class="one-fourth">Name</div>
        <div class="three-fourth">{{ reservation.name }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">Arrival Date</div>
        <div class="three-fourth">{{ reservation.date }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">From</div>
        <div class="three-fourth">{{ reservation.pickUp }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">To</div>
        <div class="three-fourth">{{ reservation.dropOff || "N/A" }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">Arrival Airline & Flight Number</div>
        <div class="three-fourth">{{ reservation.flightNumber }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">Vehicle</div>
        <div class="three-fourth">{{ reservation.vehicle }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">People</div>
        <div class="three-fourth">{{ reservation.people }}</div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "ReservationReceiptShort",
  props: {
    reservation: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.p-20 {
  padding-top: 20px;
}
.one-fourth {
  font-weight: 500;
  color: black !important;
}
.box {
  margin: 0 !important;
}
h3 {
  font-weight: 500;
}
</style>