export const DENVER_AIRPORT  = {
    "address_components": [
        {
            "long_name": "8500",
            "short_name": "8500",
            "types": [
                "street_number"
            ]
        },
        {
            "long_name": "Peña Boulevard",
            "short_name": "Peña Blvd",
            "types": [
                "route"
            ]
        },
        {
            "long_name": "Denver",
            "short_name": "Denver",
            "types": [
                "locality",
                "political"
            ]
        },
        {
            "long_name": "Denver County",
            "short_name": "Denver County",
            "types": [
                "administrative_area_level_2",
                "political"
            ]
        },
        {
            "long_name": "Colorado",
            "short_name": "CO",
            "types": [
                "administrative_area_level_1",
                "political"
            ]
        },
        {
            "long_name": "United States",
            "short_name": "US",
            "types": [
                "country",
                "political"
            ]
        },
        {
            "long_name": "80249",
            "short_name": "80249",
            "types": [
                "postal_code"
            ]
        }
    ],
        "adr_address": "<span class=\"street-address\">8500 Peña Blvd</span>, <span class=\"locality\">Denver</span>, <span class=\"region\">CO</span> <span class=\"postal-code\">80249</span>, <span class=\"country-name\">USA</span>",
        "business_status": "OPERATIONAL",
        "formatted_address": "8500 Peña Blvd, Denver, CO 80249, USA",
        "formatted_phone_number": "(303) 342-2000",
        "geometry": {
        "location": {
            "lat": 39.8560963,
                "lng": -104.6737376
        },
        "viewport": {
            "south": 39.83162935,
                "west": -104.67682735,
                "north": 39.86425195,
                "east": -104.67019755
        }
    },
    "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/airport-71.png",
        "icon_background_color": "#10BDFF",
        "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/airport_pinlet",
        "international_phone_number": "+1 303-342-2000",
        "name": "Denver International Airport",
        "photos": [
        {
            "height": 3000,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/104023725939953909775\">J STEPHEN WILLIAMS</a>"
            ],
            "width": 4000
        },
        {
            "height": 3024,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/111388885172159048260\">Colan Scheidenhelm</a>"
            ],
            "width": 4032
        },
        {
            "height": 3000,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/117712530872942603932\">Jesse Dahl</a>"
            ],
            "width": 4000
        },
        {
            "height": 9248,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/108955572070491722390\">Thayla Camilo</a>"
            ],
            "width": 6936
        },
        {
            "height": 9000,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/108072909235279309886\">Marlon Morales</a>"
            ],
            "width": 12000
        },
        {
            "height": 3024,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/103584759794065518400\">Fernando Méndez</a>"
            ],
            "width": 4032
        },
        {
            "height": 4032,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/108166640827636954334\">Dante</a>"
            ],
            "width": 3024
        },
        {
            "height": 3024,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/115899350080424233489\">Rajiv Vyas</a>"
            ],
            "width": 4032
        },
        {
            "height": 2252,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/112597261814074263114\">Mohammad Ali Rohani</a>"
            ],
            "width": 4000
        },
        {
            "height": 3024,
            "html_attributions": [
                "<a href=\"https://maps.google.com/maps/contrib/110211429399184536842\">Lisa Liu</a>"
            ],
            "width": 4032
        }
    ],
        "place_id": "ChIJ_0T_mCp_bIcRapy1NbQ7WEk",
        "plus_code": {
        "compound_code": "V84G+CG Denver, CO, USA",
            "global_code": "85FQV84G+CG"
    },
    "rating": 4.2,
        "reference": "ChIJ_0T_mCp_bIcRapy1NbQ7WEk",
        "reviews": [
        {
            "author_name": "Curt Dodds",
            "author_url": "https://www.google.com/maps/contrib/111059966462934558975/reviews",
            "language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/AOh14GhjAsGrPr5ICE3DBOk8SICW2MZT6F8GC9dIS6HHGA=s128-c0x00000000-cc-rp-mo-ba4",
            "rating": 5,
            "relative_time_description": "a month ago",
            "text": "I flew United today. Their new shortcut bag drop is wonderful but only if you have checked in using the United app. BTW, I consider the United app one of the best. Even with blowing snow and about 3in on the ground, everything flowed smoothly. The airport is far from downtown with good freeway access, nearby lodging and food. Truly the \"friendly skies\".",
            "time": 1647537208
        },
        {
            "author_name": "Patricia Quinn",
            "author_url": "https://www.google.com/maps/contrib/104952040055610208032/reviews",
            "language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a/AATXAJwx6d9JE2OTDDZ7MqdLGeAmt79qB_vADRVp13Y_=s128-c0x00000000-cc-rp-mo",
            "rating": 4,
            "relative_time_description": "a year ago",
            "text": "I had a very positive experience traveling from Denver International Airport.  The entire experience went smoothly and efficiently.  We arrived five hours early to have a relaxed experience. The baggage carts were easily accessible and was very helpful.  No struggling with heavy bags and a car seat.  The airport signs were clearly visible and the airport shuttle bus took us straight from long term parking to the correct airline and the baggage carts were within 10 feet of where we were dropped off.",
            "time": 1617939580
        },
        {
            "author_name": "Child of Shrek",
            "author_url": "https://www.google.com/maps/contrib/112486384088140008643/reviews",
            "language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/AOh14GgtmGJlVKCzvmVgUOrUFWm-9GqG3WZZnu0BWRL1NQ=s128-c0x00000000-cc-rp-mo-ba3",
            "rating": 5,
            "relative_time_description": "11 months ago",
            "text": "What a great airport. I’ve been to this airport many times in the past. It is clean, many choices for food (Panda Express!) and very pretty design. There is art and murals and stuff everywhere, cool statues, and even a train. The staff on the planes are nice, so are all of the employees. Overall a very nice airport, definitely the best airport I have been to (I’ve only been to like 5 however).",
            "time": 1620067818
        },
        {
            "author_name": "Anne Marie Morrow-Oropeza",
            "author_url": "https://www.google.com/maps/contrib/101443676670115025017/reviews",
            "language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/AOh14GgzaJuui-DXONrRy3kgl82fT08MicR9bcx81hVJHw=s128-c0x00000000-cc-rp-mo-ba5",
            "rating": 5,
            "relative_time_description": "a year ago",
            "text": "This airport is large.  It can take time to get from one gate to another, but they have the people movers that help you make your way quickly.  They also have the trains that move you quickly from one terminal to the next.  The food here is also really good!  I love it when I have time to eat between flights!  There is so much to choose from!  Wolfgang Puck's is one of my favorites.",
            "time": 1596904999
        },
        {
            "author_name": "Carisa C",
            "author_url": "https://www.google.com/maps/contrib/105906933131805292061/reviews",
            "language": "en",
            "profile_photo_url": "https://lh3.googleusercontent.com/a-/AOh14GiPHo0u-92FjhvdsiP7SuJKsludzDruEDoBqrpkZw=s128-c0x00000000-cc-rp-mo-ba4",
            "rating": 4,
            "relative_time_description": "11 months ago",
            "text": "Great airport. Clean with awesome amenities. Good food choices on each concourse and shopping as well. If you fly in to here it may become one of your favorite airports. They also have ground transportation down to a science! The train is super fast and efficient!",
            "time": 1621746479
        }
    ],
        "types": [
        "airport",
        "point_of_interest",
        "establishment"
    ],
        "url": "https://maps.google.com/?cid=5285039807900523626",
        "user_ratings_total": 28321,
        "utc_offset": -360,
        "vicinity": "8500 Peña Boulevard, Denver",
        "website": "https://www.flydenver.com/",
        "html_attributions": [],
        "utc_offset_minutes": -360
};