<template>
  <div class="strip">
    <div class="search-section" id="search">
      <div class="advanced-search search-form color">
        <div class="wrap m-b-20 " >
          <div class="f-row">
            <Select :options="tripTypes" v-model="form.type" label="Type"/>
          </div>

          <div class="f-row row-flex">
            <div class="form-group datepicker">
              <label for="date"><h5>Date</h5></label>
              <VueCtkDateTimePicker
                  no-button-now
                  color="#191F26"
                  button-color="#A67B9F"
                  format="YYYY-MM-DD"
                  formatted="YYYY-MM-DD"
                  :min-date="form.today"
                  only-date
                  autoClose
                  class="full-width date-picker"
                  id="date"
                  label=""
                  v-model="form.date"/>
            </div>

            <div class="form-group">
              <label for="time"><h5>Time</h5></label>
              <VueCtkDateTimePicker
                  no-button-now
                  color="#191F26"
                  button-color="#A67B9F"
                  format="hh:mm a"
                  formatted="hh:mm a"
                  minute-interval="5"
                  only-time
                  class="full-width time-only"
                  id="time"
                  label=""
                  v-model="form.time"/>
            </div>

            <div v-if="form.type.value === 'hourly'" class="form-group datepicker-2 ">
              <label for="until"><h5>Until</h5></label>
              <VueCtkDateTimePicker
                  no-button-now
                  color="#191F26"
                  button-color="#A67B9F"
                  format="hh:mm a"
                  formatted="hh:mm a"
                  minute-interval="5"
                  only-time
                  class="full-width"
                  id="until"
                  label=""
                  v-model="form.until"/>
            </div>
          </div>


          <div class="f-row row-flex">
            <div class="form-group one-half">
              <Select :options="passengerOptions" v-model="form.passengers" label="Passengers" />
            </div>
            <div class="form-group one-half datepicker-2" >
              <label for="flight-number"><h5>Flight #</h5></label>
              <input id="flight-number" v-model="form.flightNumber" type="text" :disabled="form.type.value === 'per-mile'"/>
            </div>
          </div>

            <div class="f-row row-flex">
              <div class="form-group full-width">
                <label for="pickUp"><h5>Pick up location</h5></label>
                <GmapAutocomplete type="text" id="pickUp" @place_changed="setPickup" :value="form.pickUp" :options="{
                                bounds: {north: 40.08240600, south: 39.452012, east: -104.589844, west: -105.30779722},
                                strictBounds: true}" :disabled="form.type.value === 'airport-pickup'"/>
                <div class="warning"><i class="fa fa-exclamation-triangle"></i>Make sure that the pick up location is in the Denver Metro area!</div>
              </div>

            </div>

            <div class="f-row row-flex"  v-if="form.type.value !== 'hourly'">
              <div class="form-group full-width">
                <label for="dropOff"><h5>Drop off location</h5></label>
                <GmapAutocomplete type="text" id="dropOff" @place_changed="setDropOff" :value="form.dropOff" :options="{
                                bounds: {north: 40.08240600, south: 39.452012, east: -104.589844, west: -105.30779722},
                                strictBounds: true}"
                                  :disabled="form.type.value === 'airport-dropoff'"/>
                <div class="warning"><i class="fa fa-exclamation-triangle"></i>Make sure that the drop off location is in the Denver Metro area!</div>
              </div>
            </div>
          <!-- Row -->
          <div class="f-row">
            <div class="form-group  right">
              <div class="error_message" v-if="error">
                {{ error }}
              </div>
              <div class="error_message" v-if="error">
                Or, <router-link class="inquiry_link" to="/inquiry">send</router-link> us an inquiry.
              </div>
              <!--            GA Tracking code inserted on next line:-->
              <button id="conversionTracker" @click="searchResults(); fireGtag()" :disabled="!isSearchEnabled" :class="{
                btn: true,
                black: true,
                large: true,
                disabled: !isSearchEnabled
              }">Search</button>
            </div>
          </div>
          <!--// Row -->
        </div>
        <div class="loader" v-if="isLoading">
        </div>
        <div class="loader-text" v-if="isLoading">We are finding the best deal for you!</div>
      </div>
      <SearchResults :form="form" :results="getResults"/>
      <router-view />
    </div>
    <div class="logo-section" id="logo">
      <div class="logo-box">
        <img src="images/logo-w.png">
      </div>
    </div>
  </div>
</template>

<script>
import SearchResults from "./SearchResults";
import server from "../axios";
import moment from "moment";
import Select from "./generic/Select";
import {DENVER_AIRPORT} from "../constants";
import { event } from 'vue-gtag';
// let Today = new Date();

export default {
  name: "SearchForm",
  components: {Select, SearchResults},
  data() {
    return {
      error: '',
      isLoading: false,
      tripTypes: [
        {
          value: "airport-pickup",
          label: "Airport pick up"
        },
        {
          value: "airport-dropoff",
          label: "Airport drop off"
        },
        {
          value: "hourly",
          label: "Per hour"
        },
        {
          value: "per-mile",
          label: "Per mile"
        }
      ],
      form: {
        today: (new Date()).toISOString().split('T')[0],
        passengers: {value: 0, label: 0},
        date: '',
        time: '',
        until: '',
        flightNumber: '',
        dropOff: '',
        pickUp: '',
        type: {
          value: "per-mile",
          label: "Per mile"
        }
      },
      results: []
    }
  },
  computed: {
    passengerOptions() {
      const opts = [0, 1, 2, 3, 4, 5, 6];
      return opts.map(o => {
        return {value: o, label: o};
      });
    },
    isSearchEnabled() {
      return !!this.form.date;
    },
    getResults() {
      if (this.results.length) {
        return this.results;
      }
      return [];
    },
  },
  methods: {

    setPickup(value) {
      this.$set(this.form, 'pickUp', value.formatted_address? value.formatted_address : value);
    },
    setDropOff(value) {
      this.$set(this.form, 'dropOff', value.formatted_address? value.formatted_address : value);
    },
    searchResults() {
      const {type, date, time, until, pickUp, dropOff, passengers} = this.form;
      this.isLoading = true;
      const fullDate = `${date} ${time}`;
      document.getElementById('logo').classList.add("dissapear");
      document.getElementById('search').classList.add("search-section-expanded");

      const params = {
        type: type.value,
        date: moment(fullDate, 'YYYY-MM-DD hh:mm a').format('YYYY-MM-DD hh:mm a'),
        pickUp: pickUp,
        dropOff: dropOff,
        people: passengers.value
      };

      if(type.value === 'hourly') {
        if(time <= until) {
          const from = moment(time, "hh:mm");
          const to = moment(until, "hh:mm");
          const diff = to.diff(from, 'hours');
          params['units'] = Number(diff);
        }
        if(time > until) {
          const from = moment(time, "hh:mm");
          const to = moment(until, "hh:mm").add(1, 'days');
          const diff = to.diff(from, 'hours');
          params['units'] = Number(diff);
        }
      }

      server.get('/offers', {
        params
      }).then(result => {
        this.results = result.data;
        this.error = '';
        this.isLoading = false;
        // document.getElementById('search-results').scrollIntoView({behavior: "smooth"});
      }).catch((e) => {
        this.error = e.response.data.message;
        this.results = [];
        this.isLoading = false;
        // this.$router.push({name: 'inquiry'});
      });
    },
    fireGtag() {
      event('Event', {
        event_category: 'Form',
        event_action: 'Submit',
        event_label: 'Denver',
        method: 'Google',
      })
    },
    beforeMount() {
      this.getDate();
    }

  },
  watch: {
    'form.type'(type){
      this.setPickup('');
      this.setDropOff('');

      if(type.value === 'airport-pickup') {
        this.setPickup(DENVER_AIRPORT);
      }
      if(type.value === 'airport-dropoff') {
        this.setDropOff(DENVER_AIRPORT);
      }
    }
  }
}

</script>

<style scoped>
.search-section {
  max-width: 40%;
  width: 40%;
}
.search-section-expanded {
  max-width: 100% !important;
  width: 100% !important;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.search-form {
}
.warning {
  font-size: 12px;
  color: #FF9966;
  text-transform: none;
}
i {
  padding-right: 3px;;
}
.strip {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
}
img {
  max-width: 50%;
}
.logo-section {
  max-width: 60%;
  display: flex;
  flex-direction: column;
  margin-top: 550px;
  height: 100%;
}
.inquiry_link{
  color: #5e0000;
  text-decoration: underline;
}
.row-flex {
  display: flex;
  flex-direction: row;
  vertical-align: bottom;
}
.radios {
  padding-bottom: 0;
  margin-bottom: 25px;
}
.error_message {
  color: yellow;
  font-weight: 800;
}
.radio-trip-type {
  width: 25px;
  height: 25px;
  margin-left: 5px
}
.advanced-search {
  scroll-margin-top: 80px;
  max-width: 500px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.loader-text {
  margin-top: 20px;
  font-weight: 600;
}
.dissapear {
  display: none;
}
.logo-box {
  display: flex;
  justify-content: right;
  margin-right: 50px;
}
.datepicker {
  margin-right: 20px;
}
.datepicker-2 {
  margin-left: 20px
}
.form-group {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 980px) {
  .strip {
    flex-direction: column;
  }
  .search-section {
    min-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .advanced-search {
    margin: 0 !important;
    width: 100%;
  }
  .logo-section {
    display: none;
  }




}
</style>