<template>
  <div class="reservation" id="reservation" >
    <ReservationBanner :reservation="reservation"/>
    <ReservationStepOne v-if="step===0" @continue="changeStep(1)" :reservation="reservation" />
    <ReservationStepThree v-if="step===1" @back="changeStep(0)" @continue="changeStep(2)"/>
    <div v-if="step ===2">
      <div class="widget">
        <header class="Heading ">
          <div class="wrap">
            <div class="container">
              <font-awesome-icon icon="check-circle" size="4x" class="icon-confirmation"/>
              <h2>Reservation Confirmed</h2>
              <h3>Thank you for choosing LimotoCo.com!</h3>
              <p class="paragraph">Please expect an e-mail confirmation within the next 24 hours. Our goal is to match you with a chauffeur within that time frame, whose information will also be provided in the confirmation. Feel free to scan the QR code to revisit the online confirmation at any time, we suggest you take a screenshot of the QR code for your own records.</p>
            </div>
          </div>
          <qrcode-vue
              background="#e6f1fa"
              foreground="#191F26"
              size="150"
              level="Q"
              style="padding-bottom: 50px; padding-top: 50px"
              class="qr" :value="`https://limotoco.com/#/reservation/${reservation._id}`" />
        </header>
      </div>
    </div>
    <About/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import QrcodeVue from 'qrcode.vue'
import ReservationStepOne from "./ReservationStepOne";
import ReservationBanner from "./ReservationBanner";
import ReservationStepThree from "./ReservationStepThree";
import About from "./About";

export default {
  name: "ReservationForm",
  components: { ReservationStepThree, ReservationBanner, ReservationStepOne, About, QrcodeVue},
  computed: {
    ...mapGetters(["reservation"])
  },
  data() {
    return {
      step: 0
    }
  },
  methods: {
    changeStep(step) {
      this.step = step;
      document.getElementById('reservation').scrollIntoView({behavior: "smooth"});
      window.scrollTo(0,0);
    }
  },
  watch: {
    $route(){
      if(!this.reservation.type){
        this.$router.replace({name: "home"});
      }
    }
  },
  created() {
    if(!this.reservation.type){
      this.$router.replace({name: "home"});
    }
  }

}
</script>

<style scoped>
.icon-confirmation {
  margin: 10px;
}
h3 {
  font-weight: 500;
  font-size: 18px;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
}
.site-title {
  margin-top: 50px;
  padding: 10px;
}
.paragraph {
  font-size: 16px;
  letter-spacing: 1.15px;
  margin: auto;
  max-width: 500px;
  text-align: justify;
}
.reservation{
  margin-top: 50px;
}

@media screen and (max-width: 600px) {
  .paragraph {
    font-size: 16px;
    margin: 0 15px;
    max-width: 500px;
    text-align: left;
  }
  .output {
    margin: 0;
    padding: 10px 0;
  }
  h2 {
    text-align: center;
  }
  .qr {
    padding-top: 20px !important;
    padding-bottom: 80px !important;
  }


}

</style>