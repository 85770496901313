<template>
  <div class="footer black" role="contentinfo" id="about">
    <div class="wrap">
      <div class="row">
        <!-- Column -->
        <article class="one-half">
          <h6>About us</h6>
          <p>Limo to Denver is a ride hailing company. Limo to Denver operates as an online concierge company for acquiring transportation. Limo to Denver matches passengers with luxury transportation companies that will provide the service.</p>
          <p>All the companies utilized by Limo to Denver have fulfilled the state requirements of the State of Colorado and the Public Utilities Commission. If you would like more information regarding our partner companies that provide the luxury transportation, feel free to contact us.</p>
        </article>
        <!-- //Column -->

        <!-- Column -->
        <article class="one-fourth">
          <h6>Need help?</h6>
          <p>Contact us :</p>
          <p class="contact-data"><span class="icon icon-themeenergy_call"></span> <a href="tel:+18558245788"> +1 (855-824-5788)</a></p>
          <p class="contact-data"><span class="icon icon-themeenergy_mail-2"></span> <a
              href="mailto:help@limotoco.com">help@limotoco.com</a></p>
        </article>
        <!-- //Column -->

        <!-- Column -->
        <article class="one-fourth">
          <h6>Follow us</h6>
          <ul class="social">
            <li><a href="https://www.facebook.com/Limotodenver" title="facebook"><i class="fa fa-fw fa-facebook"></i></a></li>
<!--            <li><a href="https://twitter.com/LimotoDenver" title="twitter"><i class="fa fa-fw fa-twitter"></i></a></li>-->
            <li><a href="https://www.instagram.com/limotoDenver/" title="instagram"><i class="fa fa-fw fa-instagram"></i></a></li>
          </ul>
<!--          <img src="images/ltv-logo.png"/>-->
        </article>
        <!-- //Column -->
      </div>

      <div class="copy">
        <p>Copyright 2021, LimotoCo. All rights reserved. </p>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .contact-data {
    display: inline-block;
  }
}

</style>