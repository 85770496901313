import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import ReservationForm from "../components/ReservationForm";
import Reservation from "../components/Reservation";
import InquiryModal from "../components/InquiryModal";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "places",
  },
});

Vue.component("v-select", vSelect);
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      {
        path: '/inquiry',
        name: 'inquiry',
        component: InquiryModal
      }
    ]
  },
  {
    path: '/reservation-form',
    name: 'reservation-form',
    component: ReservationForm
  },
  {
    path: '/reservation/:id',
    name: 'reservation',
    component: Reservation
  }
]

const router = new VueRouter({
  routes
})

export default router
