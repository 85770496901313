<template>
  <header class="header" role="banner">
    <div class="wrap">
      <!-- Logo -->
      <div class="logo">
        <router-link to="/" title="Transfers"><img src="images/logo-single-row.png" alt="Limo to Denver Logo"/></router-link>
      </div>
      <!-- //Logo -->
      <!-- Main Nav -->
      <nav role="navigation" class="main-nav">
        <ul>
          <li class="active" @click="topFunction"><router-link to="/" >Home</router-link></li>
<!--          <li class="active pointer" @click="scrollBook">Book</li>/-->
<!--          <li class="active pointer" @click="scrollHowTo">How It Works</li>-->
<!--          <li class="active pointer" @click="scrollDestinations">Destinations</li>-->
          <li class="active pointer" @click="scrollAbout">About</li>
        </ul>
      </nav>
      <!-- //Main Nav -->
      <!--Mobile Nav-->
      <nav class="mobile-nav" role="navigation">
        <div id="menuToggle">
          <input id="menuClose" type="checkbox" />
          <span></span>
          <span></span>
          <span></span>
          <ul id="menu">
            <li class="active" @click="topFunction"><router-link to="/" >Home</router-link></li>
<!--            <li class="active pointer" @click="scrollBook">Book</li>-->
<!--            <li class="active pointer" @click="scrollHowTo">How It Works</li>-->
<!--            <li class="active pointer" @click="scrollDestinations">Destinations</li>-->
            <li class="active pointer" @click="scrollAbout">About</li>
          </ul>
        </div>
      </nav>
      <!--Mobile Nav-->
    </div>
  </header>
  <!-- //Header -->
</template>

<script>
export default {
  name: "NavBar",
  methods: {
    scrollAbout()
    {
      document.getElementById('about').scrollIntoView({behavior: "smooth"});
      document.getElementById('menuClose').checked = false;
    },
    scrollBook()
    {
      document.getElementById('booking').scrollIntoView({behavior: "smooth"});
      document.getElementById('menuClose').checked = false;
    },
    scrollDestinations()
    {
      document.getElementById('destinations').scrollIntoView({behavior: "smooth"});
      document.getElementById('menuClose').checked = false;
    },
    scrollHowTo()
    {
      document.getElementById('howTo').scrollIntoView({behavior: "smooth"});
      document.getElementById('menuClose').checked = false;
    },
    topFunction()
    {
      document.body.scrollIntoView({behavior: "smooth"}).scrollTop = 0.;
      document.documentElement.scrollIntoView({behavior: "smooth"}).scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
}
}

</script>

<style scoped>
img{
  width: 200px;
  height: auto;
}
.pointer {
  cursor:pointer;
}
.mobile-nav {
  display: none;
}
@media screen and (max-width: 600px) {
  .mobile-nav {
    display: flex;
  }
  .main-nav {
    display: none;
  }
  .logo {
    left: 50%;
  }

}

</style>