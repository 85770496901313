import Vue from 'vue'
import App from './App.vue'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import router from './router';
import store from './store';
import VueGtag from "vue-gtag";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faExclamationCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCheckCircle, faExclamationCircle, faEnvelope);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);


Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: {
    id: "UA-234839168-1",
    params: {
      send_page_view: true
    }
  }
})
new Vue({
  VueGtag,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
