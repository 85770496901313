<template>
  <div class="strip" id="howItWorks">
  <header> <h1>How Limo to Denver Works</h1></header>
  <div class="infographic">
    <div class="step one">
      <div class="text-box">
        <h2>Book Online</h2>
        <p>You provide us with your trip details, one way or round trip, location of pick up and drop off, the vehicle you desire, the number of passengers, your contact details and make the reservation online.</p>
      </div>
      <div class="timeline-point">
        <span class="dot"></span>
      </div>
      <div class="icon-box">
        <img src="../../public/images/uploads/checked.png" alt="Check"/>
      </div>
    </div>
    <div class="step two">
      <div class="text-box">
        <h2>Online Confirmation</h2>
        <p>Once your booking is confirmed, you’ll receive an online confirmation with a QR code giving you instant access to your confirmation at any time.</p>
      </div>
      <div class="timeline-point">
        <span class="dot"></span>
      </div>
      <div class="icon-box">
        <img src="../../public/images/uploads/qr.png" alt="qrcode" />
      </div>
    </div>
    <div class="step three">
      <div class="text-box">
        <h2>Reservation Details</h2>
        <p> Within 24 hours of booking we will e-mail a confirmation with your booking details, as well as the contact information for the transportation provider.</p>
      </div>
      <div class="timeline-point">
        <span class="dot"></span>
      </div>
      <div class="icon-box">
        <img src="../../public/images/uploads/icon3.png" alt="Trip Details" />
      </div>
    </div>
    <div class="step four">
      <div class="text-box">
        <h2>Day of Reservation</h2>
        <p> On the day of your reservation, the chauffeur will meet you at your pick up location and drive you to your destination.</p>
      </div>
      <div class="timeline-point">
        <span class="dot"></span>
      </div>

      <div class="icon-box">
        <img src="../../public/images/uploads/chauffeur.png" alt="Chauffeur" />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "HowItWorks",
}
</script>

<style scoped>
.strip {
  display: flex;
  background: #191F26;
  flex-direction: column;
  align-items: center;
  scroll-margin-top: 80px;
}
.infographic {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 500px;
  padding: 20px;
  max-width: 1080px;
}
header {
  padding: 30px;
  color: whitesmoke;
}
h1 {
  font-weight: 700;
}
.step {
  flex-grow: 1;
  padding: 0;
  max-width: 25%;
}
.one {
}
.two{
  display: flex;
  flex-direction: column-reverse;
}
.three {
}
.four {
  display: flex;
  flex-direction: column-reverse;
}
.text-box {
  height: 50%;
  padding: 20px;
  color: whitesmoke;
}
.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  border-top: solid #e3caca 1px;
  border-radius: 50%;
  padding: 20%;
}
.timeline-point {
  display: flex;
  align-items: center;
  height: 1px;
  background: whitesmoke;
  justify-content: center;
}

.dot {
  display: flex;
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #e3caca;
  border-radius: 50%;
}
.two .icon-box {
  border-bottom: solid #e3caca 1px;
  border-top: none;
}
.four .icon-box {
  border-bottom: solid #e3caca 1px;
  border-top: none;
}
h2 {
  font-size: 20px;
}
p {
  font-size: 14px;
}
@media screen and (max-width: 600px) {
  .infographic {
    display: flex;
    flex-direction: column;
  }
  .step {
    display: flex;
    /*flex-direction: row;*/
    /*flex-grow: 1;*/
    padding: 0;
    max-height: 25%;
    max-width: unset;
  }
  .two{
    display: flex;
    flex-direction: row-reverse;
  }
  .four {
    display: flex;
    flex-direction: row-reverse;
  }
  .timeline-point {
    width: 1px;
    height: unset;
    align-items: start;
  }
  .icon-box {
    border: none !important;
    display: flex;
    align-items: start;
    justify-content: center;
    width: 50%;
    height: unset;
    border-top: solid #e3caca 1px;
    border-radius: 50%;
    padding: 40px;
  }
  .text-box {
    width: 50%;
    height: unset;
  }
  h2 {
    font-size: 16px;
  }
  p{
    font-size: 12px;
  }
}
</style>