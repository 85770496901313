<template>
  <div class="output color twoway" >
    <div class="wrap" >
      <div>
        {{reservation.date}}
        <p>{{reservation.pickUp}}</p>
      </div>

      <div>
        {{reservation.timeRequired}} later
        <p>{{ reservation.dropOff}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReservationBanner",
  props: {
    reservation: {
      type: Object,
      default: () => {}
    }
  },
  // methods: {
  //   // eslint-disable-next-line vue/return-in-computed-property
  //   getDestinations() {
  //     const {reservation} = this.reservation
  //     var destinations;
  //     switch (reservation.toDestination){
  //   case "Eagle":
  //     destinations = "EGE"
  //     }
  //     console.log(destinations);
  //   }
  // }

}


</script>

<style scoped>


@media screen and (max-width: 600px) {
  .wrap div {
    border-bottom: none !important;
    box-shadow: none;
    border-left: none !important;
    margin: 0;
  }
  .wrap {
    display: flex;
  }
}

</style>