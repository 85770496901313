<template>
  <div>
      <main class="main" role="main" id="home">
      <!-- Intro -->
      <div class="intro">
        <div class="mobile-only">
          <img src="images/wp.jpg">
        </div>
        <SearchForm/>
      </div>

        <TermsModal />
      <!-- //Intro -->
<!--      <CustomRidesStrip/>-->
      <HowItWorks/>
      <div class="fullscreen-image">
        <img src="images/denver-skyline.jpg">
      </div>
        <faq/>
    </main>
    <!-- //Main -->

    <!-- Footer -->
    <About/>
    <!-- //Footer -->

  </div>
</template>

<script>
import About from "./About";
import SearchForm from "./SearchForm";
import HowItWorks from "./HowItWorks";
import TermsModal from "./TermsModal";
import Faq from "./Faq";
// import CustomRidesStrip from "./CustomRideStrip";

export default {
  name: "Home",
  components: { TermsModal, HowItWorks, SearchForm, About, Faq},
  methods: {
    scrollAbout() {
      document.getElementById('about').scrollIntoView({behavior: "smooth"});
    },
  }
}
</script>

<style scoped>
.transparent-bg {
  background: rgba(255,255,255,0.4);
}
.fullscreen-image {
    background-color: #191F26;
  padding: 30px 0;
}
.mobile-only {
  display: none;
}
@media screen and (max-width: 980px) {
  .mobile-only {
    display:flex;
  }

  .intro {
    background: none;
    flex-direction: column;
    height: unset;
    overflow: hidden;
    float: left;
    width: 100%;
    min-height: 768px;
  }
}
</style>