<template>
 <div class="form-group select full-width">
   <label><h5>{{ label }}</h5></label>

   <select :value="selected" @input="onInput" ref="select">
     <option v-for="option in options" :value="option.value" :key="option.value">{{option.label}}</option>
   </select>
 </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    label: {
      type: String,
      default: 'Select'
    },
    value: {
      type: [String, Number, Object],
      required: true
    },
    options: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      selected: ''
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', this.options.find(o => o.value.toString() === e.target.value.toString()));
    }
  },
  mounted() {
    this.selected = this.value.value;
  },
  watch: {
    value(newVal) {
      this.selected = newVal.value;
    }
  }
}
</script>

<style scoped>

</style>