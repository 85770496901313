<template>
  <div class="three-fourth" v-if="reservation">
    <form class="box readonly">
      <h3> Passenger details </h3>
      <div class="f-row">
        <div class="one-fourth">Name</div>
        <div class="three-fourth">{{ reservation.name }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">Mobile number</div>
        <div class="three-fourth">{{ reservation.number }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">Email address</div>
        <div class="three-fourth">{{ reservation.email }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">Pick up address</div>
        <div class="three-fourth">{{ reservation.pickUpAddress }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">Drop off address</div>
        <div class="three-fourth">{{ reservation.dropOffAddress }}</div>
      </div>

      <h3>Arrival Transfer details</h3>
      <div class="f-row">
        <div class="one-fourth">Date</div>
        <div class="three-fourth">{{ reservation.date }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">From</div>
        <div class="three-fourth">{{ reservation.pickUp }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">To</div>
        <div class="three-fourth">{{ reservation.dropOff / "N/A" }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">Airline & Flight Number</div>
        <div class="three-fourth">{{ reservation.flightNumber }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">Vehicle</div>
        <div class="three-fourth">{{ reservation.vehicle }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">People</div>
        <div class="three-fourth">{{ reservation.people }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">Luggage</div>
        <div class="three-fourth">{{ reservation.suitcases }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">Convertible child seatss</div>
        <div class="three-fourth">{{ reservation.children }}</div>
      </div>
      <div class="f-row">
        <div class="one-fourth">Booster Seats</div>
        <div class="three-fourth">{{ reservation.booster }}</div>
      </div>


      <h5>Rate: ${{reservation.price}}</h5>
      <h5>Booking Fee: ${{ (0.035*reservation.price).toFixed(2) }}</h5>
      <h3>Total: ${{ (0.035*reservation.price + reservation.price).toFixed(2) }}</h3>

    </form>
  </div>
</template>

<script>
export default {
  name: "ReservationReceipt",
  props: {
    reservation: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

h3 {
  font-weight: 500;
}
</style>