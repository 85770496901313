<template>
  <aside class="one-fourth sidebar right">
    <!-- Widget -->
    <div class="widget">
      <h4>Reservation summary</h4>
      <div class="summary">
        <div>
          <h5>ARRIVAL</h5>
          <dl>
            <dt>Date</dt>
            <dd>{{reservation.date}}</dd>
            <dt>From</dt>
            <dd>{{reservation.pickUp}}</dd>
            <dt>To</dt>
            <dd>{{reservation.dropOff || "N/A" }}</dd>
            <dt>Vehicle</dt>
            <dd>{{ reservation.vehicle }}</dd>
          </dl>
        </div>
        
        <div class="price">
          <dl class="subtotal">
            <dt>Rate</dt>
            <dd>${{ reservation.price }}</dd>
          </dl>
          <dl class="subtotal">
            <dt>Fee</dt>
            <dd>${{ (0.035*reservation.price).toFixed(2) }}</dd>
          </dl>
        </div>
        <dl class="total-box">
          <div class="total">
            <dt>Total </dt>
            <dd>${{(0.035*reservation.price + reservation.price).toFixed(2)}} </dd>
          </div>
          <i>(Includes taxes and fees)</i>

        </dl>
      </div>
    </div>
    <!-- //Widget -->
  </aside>
</template>

<script>
export default {
  name: "ReservationSummary",
  props: {
    reservation: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>
.subtotal {
  font-weight: 500;
}
.total-box {
  display: flex;
  padding: 17px 20px 12px;
  flex-direction: column;
  background: #191F26;
  color: #FFFFFF;
}
.total {
  padding: 0;
}
@media screen and (min-width: 992px ){
  .sidebar {
    position: fixed;
    top: 390px;
    right: 20px;
  }
}

</style>