<template>
  <section>
    <div class="container">
      <div class="accordion">
        <div class="accordion-item margin-top" id="question1">
          <a class="accordion-link" href="#question1">
            <div class="flex">
              <h3>WHY LIMO TO DENVER?</h3>
            </div>
            <i class="icon ion-md-arrow-forward"></i>
            <i class="icon ion-md-arrow-down"></i>
          </a>
          <div class="answer">
            <p>At Limo to Denver, we work with several licensed and commercially insured limousine companies in the Denver Metro area to get you the best price on your airport transfer. We pride ourselves on providing you the highest quality service at the most reasonable price. You can choose the vehicle you want, and travel in style and comfort, whether it's a private SUV or a private van.</p>
          </div>
          <hr>
        </div>
        <div class="accordion-item margin-top2" id="question2">
          <a class="accordion-link" href="#question2">
            <div class="flex">
              <h3>WHY ONLY DENVER METRO?</h3>
            </div>
            <i class="icon ion-md-arrow-forward"></i>
            <i class="icon ion-md-arrow-down"></i>
          </a>
          <div class="answer">
            <p>Limo to Denver can only instantly confirm trips inside the Denver Metro area. For trips outside of these areas, we will need to confirm availability, please reach out to us at (855) 824-5788.</p>
          </div>
          <hr>
        </div>
        <div class="accordion-item margin-top2" id="question3">
          <a class="accordion-link" href="#question3">
            <div class="flex">
              <h3>IS LIMO TO DENVER PRIVATE OR SHARED RIDE?</h3>
            </div>
            <i class="icon ion-md-arrow-forward"></i>
            <i class="icon ion-md-arrow-down"></i>
          </a>
          <div class="answer">
            <p>All of our trips are private SUV or van trips, we do not offer shared shuttle. Our fleet consists of SUVs and Sprinter vans. We never mix different parties in the same vehicle.</p>
          </div>
          <hr>
        </div>
        <div class="accordion-item margin-top" id="question4">
          <a class="accordion-link" href="#question4">
            <div>
              <h3>IS MY RESERVATION INSTANTLY CONFIRMED?</h3>
            </div>
            <i class="icon ion-md-arrow-forward"></i>
            <i class="icon ion-md-arrow-down"></i>
          </a>
          <div class="answer">
            <p>Upon booking, your trip is instantly confirmed and your chauffeur will be assigned. You will receive an email confirmation in addition to the online confirmation within 24 hours of completion of the booking.</p>
          </div>
          <hr>
        </div>
        <div class="accordion-item margin-top" id="question5">
          <a class="accordion-link" href="#question5">
            <div>
              <h3>CAN I ADD A STOP?</h3>
            </div>
            <i class="icon ion-md-arrow-forward"></i>
            <i class="icon ion-md-arrow-down"></i>
          </a>
          <div class="answer">
            <p>At Limo to Denver, one stop of 10 minutes or less is allowed per trip. If you need to arrange a longer stop, please call us after booking or arrange it directly with your chauffeur. Including a stop on the day of reservation is dependant on availability.</p>
          </div>
          <hr>
        </div>
        <div class="accordion-item margin-top" id="question6">
          <a class="accordion-link" href="#question6">
            <div>
              <h3>WHAT IS THE CANCELLATION POLICY?</h3>
            </div>
            <i class="icon ion-md-arrow-forward"></i>
            <i class="icon ion-md-arrow-down"></i>
          </a>
          <div class="answer">
            <p>In case of cancellation of the reservation that happens up to 48 hours prior to the pick-up time, the user will be refunded in its entirety. Cancellations inside the 48 hours window prior to the pick-up time will not be refunded.</p>
          </div>
          <hr>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "faq",
}
</script>

<style scoped>
*,
*::before,
*::after{
  margin:0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-family: 'DM Sans', sans-serif;
  font-size: 10px;
  box-sizing: border-box;
}

/*Section used for centering process*/
section {
  width: 100%;
  background-color: #e3caca;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
}

.container{
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.accordion-item{
  background-color: #e3caca;
  border-radius: .4rem;
}

.margin-top {
  scroll-margin-top: 90px;
}
/*.margin-top2 {*/
/*  scroll-margin-top: 80px;*/
/*}*/

.accordion-item hr{
  border: 1px solid #191F26;
}

.accordion-link{
  font-size: 1.6rem;
  color: #191F26;
  text-decoration: none;
  background-color: #e3caca;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.accordion-link h3{
  font-weight:500;
  font-size: 20px;
}

.accordion-link i{
  color: #191F26;
  padding: .5rem;

}

.accordion-link ul{
  display:flex;
  align-items: flex-end;
  list-style-type: none;
  margin-left: 25px;
}

.accordion-link li{
  font-size: 10px;
  color: #191F26;
  padding: 0 0 1px 5px;
}

.accordion-link div{
  display: flex;
  scroll-margin-top: 80px
}

.accordion-link .ion-md-arrow-down{
  display: none;
}

.answer{
  max-height: 0;
  overflow: hidden;
  position: relative;
  background-color: #e3caca;
  transition: max-height 650ms;
}



.answer p{
  color: #191F26;
  font-size: 15px;
  padding: 2rem;

}

.accordion-item:target .answer{
  max-height: 20rem;
}

.accordion-item:target .accordion-link .ion-md-arrow-forward{
  display: none
}

.accordion-item:target .accordion-link .ion-md-arrow-down{
  display: block;
}

@media screen and (max-width: 600px) {
  section {
    padding:10px;
  }
  p {
    padding: 10px!important;
    text-align: left;
  }
}
</style>
