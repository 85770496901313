import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        reservation: {}
    },
    mutations: {
        SET_RESERVATION(state, value) {
            state.reservation = value;
        },
        UPDATE_RESERVATION(state, value) {
            state.reservation = {
                ...state.reservation,
                ...value
            };
        }
    },
    actions: {
        setReservation({commit}, {reservation}) {
            commit('SET_RESERVATION', reservation)
        },
        updateReservation({commit}, {data}) {
            commit('UPDATE_RESERVATION', data);
        }
    },
    getters: {
        reservation(state) {
            return state.reservation;
        }
    }
})
