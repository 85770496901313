<template>
  <div>
    <header class="site-title color">
      <div class="wrap pl-10">
        <div class="container">
          <font-awesome-icon class="email-icon" icon="envelope" size="2x"/>
          <h1>Send us an e-mail inquiry</h1>
        </div>
      </div>
    </header>

    <div class="wrap">
      <input placeholder="Your e-mail address" v-model="from" type="text" id="from"/>
      <textarea v-model="content" placeholder="Your message" class="inquiry"></textarea>
      <div class="error_message" v-if="error">
        {{ error }}
      </div>
      <div class="actions">
        <button @click="goBack" class="btn medium back">Go back</button>
        <button @click="onSend"
                id="lead"
                class="btn color right medium">Send
        </button>
      </div>
    </div>


  </div>
</template>

<script>
import server from "../axios";

export default {
  name: "Inquiry",
  methods: {
    goBack() {
      this.$router.replace({name: 'home'})
    },
    onSend() {
      const {from, content} = this;
      server.post('/offers', {
        from,
        content
      }).then(() => {
        this.error = '';
        this.$router.go(-1);
      }).catch(err => {
        this.error = err;
      })
    }
  },
  data() {
    return {
      from: '',
      content: '',
      error: ''
    }
  }
}
</script>

<style scoped>
.actions {
  padding-bottom: 20px;
}
.inquiry {
  margin-top: 10px;
}
.email-icon {
  margin-top: 30px;
}
.site-title {
  margin-top: 50px;
}
</style>