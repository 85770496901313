import axios from "axios";

const baseURL = process.env.NODE_ENV === 'development'?
    'http://localhost:3003' : 'https://limotoden-api.herokuapp.com';
const server = axios.create({
    baseURL,
    headers: {
        'Authorization': `Bearer ${process.env.VUE_APP_SECRET}`
    }
});

export default server;

