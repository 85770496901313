<template>
  <div>
    <!-- Page info -->
<!--    <header class="site-title color">-->
<!--      <div class="wrap">-->
<!--        <div class="container">-->
<!--          <h1>Please confirm the information and agree to the terms and conditions.</h1>-->
<!--        </div>-->
<!--      </div>-->
<!--    </header>-->
    <!-- //Page info -->

    <div class="wrap">
      <div class="row">
        <div class="reservation-terms">
        <ReservationReceiptShort :reservation="reservation">
        </ReservationReceiptShort>
        <div class="terms-only">

        <input type="checkbox" class="checkbox-terms" size="large" id="one" :value="true" v-model="agreeTerms">
        <label for="one">I agree to the </label> <a id="terms" @click="$refs.termsModal.onShow()" >terms and conditions</a>
<!--        <button class="btn color large" @click="$refs.termsModal.onShow()">Terms</button>-->
      </div>
        </div>
      <TermsModal ref="termsModal"/>
        <div>
          <form id="payment-form" class="three-fourth" v-if="reservation">
<!--            <span style="padding:0">Would you like to add gratuity?</span>-->
<!--            <div class="tip-options">-->
<!--              <div class="tip-option">-->
<!--                <input class="radio-trip-type" type="radio" id="none" :value="0" v-model="tipAmount"/>-->
<!--                <label for="none">No</label>-->
<!--              </div>-->
<!--              <div class="tip-option">-->
<!--                <input class="radio-trip-type" type="radio" id="10" :value="0.1*reservation.price" v-model="tipAmount"/>-->
<!--                <label for="10">10%</label>-->
<!--              </div>-->
<!--              <div class="tip-option">-->
<!--                <input class="radio-trip-type" type="radio" id="15" :value="0.15*reservation.price" v-model="tipAmount"/>-->
<!--                <label for="15">15%</label>-->
<!--              </div>-->
<!--              <div class="tip-option">-->
<!--                <input class="radio-trip-type" type="radio" id="20" :value="0.2*reservation.price" v-model="tipAmount"/>-->
<!--                <label for="20">20%</label>-->
<!--              </div>-->
<!--            </div>-->
            <div class="totals">
              <h3>{{reservation.pickUp}} to {{reservation.dropOff}} {{ reservation.vehicle}} Rate: ${{reservation.price}}</h3>
              <h3>Booking Fee: ${{ (0.035*reservation.price).toFixed(2) }}</h3>
              <h1>Total: ${{ (0.035*reservation.price + reservation.price + tipAmount).toFixed(2) }}</h1>
            </div>
            <div id="card-container"></div>



            <div class="actions">
              <button @click="$emit('back')" class="btn medium back">Go back</button>
              <button v-if="agreeTerms"  class="btn medium color right" id="card-button" type="button" :disabled="disabled" @click="handlePayment">
                Confirm
              </button>
            </div>

          </form>
          <div class="loader" v-if="disabled"></div>
          <div class="error_message" v-if="error">{{ error }}</div></div>

      <!--- Sidebar -->
      <aside class="one-fourth sidebar right">
        <!-- Widget -->
        <div class="widget">
          <h4>Need help booking?</h4>
          <div class="textwidget">
            <p>Call our customer services team on the number below to speak to one of our advisors who will help you
              with all of your needs.</p>
            <p class="contact-data"><span class="icon icon-themeenergy_call black"></span><a href="tel:+18558245788"> +1 (855) 824-5788</a></p>
          </div>
        </div>
        <!-- //Widget -->
      </aside>
      <!--- //Sidebar -->
    </div>
  </div>
  </div>

</template>

<script>
import server from "../axios";
import {mapActions, mapGetters} from "vuex";
import ReservationReceiptShort from "./ReservationReceiptShort";
import TermsModal from "./TermsModal";

export default {
  name: "ReservationStepThree",
  components: { TermsModal, ReservationReceiptShort},
  computed: {
    ...mapGetters(["reservation"])
  },
  data() {
    return {
      agreeTerms: false,
      card: null,
      disabled: false,
      tipAmount: 0,
      error: '',
    }
  },
  methods: {
    ...mapActions(["setReservation"]),
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(
              tokenResult.errors
          )}`;
        }
        throw new Error(errorMessage);
      }
    },
    async handlePayment(event) {
      event.preventDefault();
      const paymentMethod = this.card;

      try {
        // disable the submit button as we await tokenization and make a
        // payment request.
        this.disabled = true;
        const token = await this.tokenize(paymentMethod);
        this.error = '';

        const body = {
          locationId: process.env.VUE_APP_SQUARE_LOCATION_ID,
          sourceId: token,
          tipAmount: this.tipAmount,
          ...this.reservation,
        }
        server.post('/reservations', body).then(res => {
          this.setReservation({reservation: {...res.data}});
          this.$emit('continue');
          this.disabled = false;
        }).catch(err => {
          this.error = err.response.data.message;
          this.disabled = false;
        });
      } catch (e) {
        this.disabled = false;
        this.error = 'Invalid card credentials';
      }
    },
    async initializeCard(payments) {
      const card = await payments.card();
      await card.attach('#card-container');
      return card;
    },
    onOk() {
      this.agreeTerms = true;
    },
    scrollTerms() {
      document.getElementById('terms').scrollIntoView({behavior: "smooth"});
    },
  },
  async created() {
    const {VUE_APP_SQUARE_APPLICATION_ID, VUE_APP_SQUARE_LOCATION_ID} = process.env;
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }
    const payments = window.Square.payments(VUE_APP_SQUARE_APPLICATION_ID, VUE_APP_SQUARE_LOCATION_ID);
    try {
      this.card = await this.initializeCard(payments);
    } catch (e) {
      console.error('Initializing Card failed', e);
    }
  }

}
</script>

<style scoped>
span {
  font-size: 18px;
  font-weight: 600;
}
.totals {
  margin: 30px 0;
}
.totals h3 {
  margin: 5px 5px;
  padding: 0;
}
.totals h1 {
  border-top: 1px solid black;
  text-align: right;
  font-weight: 600;
}
.terms-only {
  display: flex;
  height: 40px;
  align-items: center;
  margin-bottom: 20px;
}
input.checkbox-terms {
  width: 15px;
  height: 15px;
  margin: 12px;
}
label, a {
  padding: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
}
a {
  padding-left: 5px;
  cursor: pointer;
}
.reservation-terms {
  display: flex;
  flex-direction: column;
}
.actions {
  float: left;
  padding: 0 15px 30px;
  max-width: 100%;
}

@media screen and (max-width: 980px) {
  .actions {
    width: 100%;
  }
}

input[type="radio"] {
  display: none;
  /*height: 20px;*/
  /*width: 20px;*/
}
* {
  box-sizing: border-box;
}

.tip-amount {
}
.tip-options {
  margin-top: 20px;

  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  justify-content: space-around;
}
body {
  font-family: Montserrat, sans-serif;
}

#payment-form {
  max-width: 550px;
  min-width: 300px;
  padding: 15px 30px;
}
#tipAmount {
  margin-bottom: 20px;
  margin-top: 20px;
}

.tip-option label {
  width: 100%;
  border: 1px solid #191F26;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  min-width: 40px;
  background: none;
  cursor: pointer;
  /*padding: 20px !important;*/
  background: #FFFFFF;
  /*margin-top: 20px;*/
  /*margin-left: 10px;*/
}
.tip-option input[type="radio"]:checked+label {
background: #A67B9F;
}

.buyer-inputs {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  border: none;
  margin: 0;
  padding: 0;
}
.radio-trip-type {
  width: 25px;
  height: 25px;
  margin-left: 5px
}

#card-container {
  /*margin-top: 45px;*/
  /* this height depends on the size of the container element */
  /* We transition from a single row to double row at 485px */
  /* Settting this min-height minimizes the impact of the card form loading */
  min-height: 90px;
}

#gift-card-container {
  margin-top: 45px;
  min-height: 90px;
}

@media screen and (max-width: 500px) {
  #card-container {
    min-height: 140px;
  }
}

#ach-button {
  margin-top: 20px;
}

#landing-page-layout {
  width: 80%;
  /*margin: 150px auto;*/
  max-width: 1000px;
}

#its-working {
  color: #737373;
}

#example-container {
  width: 100%;
  border: 1px solid #b3b3b3;
  padding: 48px;
  margin: 32px 0;
  border-radius: 12px;
}

#example-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

h3 {
  margin: 0;
}

p {
  line-height: 24px;
}

label {
  font-size: 16px;
  /*width: 100%;*/
}

input {
  padding: 12px;
  width: 100%;
  border-radius: 5px;
  border-width: 1px;
  margin-top: 20px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

input:focus {
  border: 1px solid #006aff;
}

button {
  user-select: none;
}

button:active {
  background-color: rgb(0, 85, 204);
}

button:disabled {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.3);
}

#payment-status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 50px;
  margin: 0 auto;
  width: 225px;
  height: 48px;
  visibility: hidden;
}

#payment-status-container.missing-credentials {
  width: 350px;
}

#payment-status-container.is-success:before {
  content: '';
  background-color: #00b23b;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
  mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
}

#payment-status-container.is-success:after {
  content: 'Payment successful';
  font-size: 14px;
  line-height: 16px;
}

.error_message {
  text-align: center;
}

#payment-status-container.is-failure:before {
  content: '';
  background-color: #cc0023;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
  mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
}

#payment-status-container.is-failure:after {
  content: 'Payment failed';
  font-size: 14px;
  line-height: 16px;
}

#payment-status-container.missing-credentials:before {
  content: '';
  background-color: #cc0023;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
  mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
}

#payment-status-container.missing-credentials:after {
  content: 'applicationId and/or locationId is incorrect';
  font-size: 14px;
  line-height: 16px;
}


</style>